<template>
  <div class="article-post-page">
    <div class="container">
      <breadcrumbs :post_title="post_title" :homePath="homePath" />
      <div class="row">
        <div class="col-sm-12">
          <h1 class="dark">News Releases</h1>
          <hr class="content-seperator" />
        </div>
      </div>
      <div class="article-post">
        <div class="row article-post-row">
          <div class="col-sm-12">
            <div class="post-title">
              <h2>{{ post.fields.title }}</h2>
            </div>
            <div class="post-meta">
              <p>{{ post.fields.date | formatDate }}</p>
            </div>
            <div class="post-image" v-if="post.fields.featuredImage">
              <img
                :src="post.fields.featuredImage.fields.file.url"
                class="img-fluid"
                :alt="post.fields.title"
              />
            </div>
            <div class="post-body" v-html="body"></div>
          </div>
        </div>
      </div>
    </div>
      <!--<AddThis :title="post_title" :description="metaDescription" :media="featuredImage" />-->
  </div>
</template>
<script>
//import { client } from "@/contentful";
import { marked } from "marked";
import { brandKeys } from "@/mixins/brandKeys.js";
//import AddThis from '../components/AddThis.vue'
import Breadcrumbs from "../components/Breadcrumbs.vue";

export default {
  name: "NewsReleasePost",
  mixins: [brandKeys],
  components: {
    //AddThis,
    Breadcrumbs,
  },
  data() {
    return {
      post: {},
      featuredImage: "",
      post_title: "",
      body: "",
      metaDescription: ""
    };
  },
  methods: {
    getPost() {
const contentful = require('contentful')
const client = contentful.createClient({
  space: 'kfegpgrfs082',
  accessToken: 'hbEDV9OINCfZ1kQ4TEPheIR5w4cQQSo0mS8qggtk7Gk',
  host: 'preview.contentful.com'
});
client.getEntries({
          content_type: "launch",
          "fields.slug": this.$route.params.slug,
        })
        .then((res) => {
          console.log(res.items[0]);
          this.post = res.items[0];
          this.post_title = res.items[0].fields.title;
          this.metaDescription = res.items[0].fields.excerpt;
          this.body = marked(res.items[0].fields.body);
          this.featuredImage =
          res.items[0].fields.featuredImage.fields.file.url;
        });

    },
    /*addThis() {      
    document.querySelector('body').classList.add('show-addthis');
    }*/

  },
  mounted() {
    this.getPost();
    //this.addThis();
  },
  watch: {
    "$route.params": function () {
      this.getPosts();
      //this.addThis();
    },
  },
  beforeDestroy() {
    document.querySelector("body").classList.remove("show-addthis");
  },
  metaInfo() {
    return {
      title: this.post_title,
      titleTemplate: `%s | ${this.brandName}`,
      meta: [
        {
          vmid: "description",
          name: "description",
          content: this.metaDescription,
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: this.post_title,
        },
        {
          vmid: "og:description",
          property: "og:description",
          content: this.metaDescription,
        },
        {
          vmid: "og:type",
          property: "og:type",
          content: "website",
        },
        {
          vmid: "og:image",
          property: "og:image",
          content: this.featuredImage,
        },
        {
          vmid: "og:locale",
          property: "og:locale",
          content: "en_US",
        },
        {
          vmid: "og:url",
          property: "og:url",
          content: `https://resources.hubbellwiringsystems.com${this.$route.path}`,
        },
        {
          vmid: "twitter:card",
          name: "twitter:card",
          content: "summary",
        },
        {
          vmid: "twitter:title",
          name: "twitter:title",
          content: this.post_title,
        },
        {
          vmid: "twitter:description",
          name: "twitter:description",
          content: this.metaDescription,
        },
        {
          vmid: "twitter:image",
          name: "twitter:image",
          content: this.featuredImage,
        },
      ],
    };
  },
};
</script>
<style>
.article-post {
  max-width: 800px;
}
.post-image img {
  float: right;
  margin: 1em 2em;
  object-fit: contain;
  max-width: 300px;
  width: 100%;
}
.post-body {
  line-height: 1.6em;
  text-align: justify;
  text-align-last: left;
}
.post-body p:first-child {
  margin-top: 2em;
}
.article-post ol,
.article-post ul {
  margin-left: 30px !important;
  font-size: 1em !important;
}
.post-body img {
  width: 100%;
  height: auto;
}
.post-meta-description h2 {
  font-size: 1.4em;
  margin-top: 2em;
}
.post-meta {
  margin-top: 1em;
}
@media(max-width: 768px) {
  .post-image img {
  float: unset;
  margin: 1em 0;
}
}
</style>