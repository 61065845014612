import Vue from 'vue'
import App from './App.vue'
import router from './router'
import moment from 'moment'
import VueMeta from 'vue-meta'
import cors from 'cors'
import VueCountdown from '@chenfengyuan/vue-countdown';
import "@/assets/css/main.css"
Vue.component(VueCountdown.name, VueCountdown);
Vue.config.productionTip = false
Vue.use(VueMeta)
Vue.use(cors)
var filter = function(text, length, clamp){
  clamp = clamp || '...';
  var node = document.createElement('div');
  node.innerHTML = text;
  var content = node.textContent;
  return content.length > length ? content.slice(0, length) + clamp : content;
};
Vue.filter('truncate', filter);

Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('MM/DD/YYYY')
  }
});


new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

