<template>
<div>
  <WDKHeader v-if="brand == 'wdk'"/>
  <HPWHeader v-if="brand == 'hpw'"/>
  <BRYHeader v-if="brand == 'bry'"/>
</div>
</template>
<script>
import { brandKeys } from "@/mixins/brandKeys.js";
import WDKHeader from "@/components/Headers/WDKHeader.vue"
import HPWHeader from "@/components/Headers/HPWHeader.vue"
import BRYHeader from "@/components/Headers/BRYHeader.vue"
export default {
  mixins: [brandKeys],
  components: {
    WDKHeader,
    HPWHeader,
    BRYHeader
  }
};
</script>