<template>
  <div class="article-listing">
    <div class="container">
      <breadcrumbs />
      <div class="row">
        <div class="col-sm-8">
          <h1 class="dark">News Releases</h1>
        </div>
        <div class="col-sm-4 text-right">
          <input
            type="text"
            class="form-control search-form"
            v-model="search"
            placeholder="Search News Releases"
          />
          <i class="icon-search"></i>
        </div>
        <div class="col-xs-12"><hr class="content-seperator" /></div>
      </div>
      <div class="article-list">
        <div
          class="article-post-row"
          v-for="post in filteredList"
          :key="post.sys.id"
        >
          <div v-if="post.fields.featuredImage" class="article-img-wrapper">
            <router-link :to="`/${brand}/news-releases/${post.fields.slug}`">
              <img
                v-if="post.fields.featuredImage"
                :src="post.fields.featuredImage.fields.file.url"
                class="img-responsive article-list-featured-img"
                :alt="post.fields.title"
              />
            </router-link>
          </div>
          <div class="article-content-wrapper">
            <div class="article-post-title">
              <router-link :to="`/${brand}/news-releases/${post.fields.slug}`"
                ><h2 class="post-title">
                  {{ post.fields.title }}
                </h2></router-link
              >
            </div>
            <div class="article-meta">
              <p>{{ post.fields.date | formatDate }}</p>
            </div>
            <div class="post-excerpt">
              <p v-if="post.fields.excerpt">
                {{ post.fields.excerpt | truncate(225, "...") }}
              </p>
              <p v-else>{{ post.fields.body | truncate(225, "...") }}</p>
            </div>
            <router-link
              :to="`/${brand}/news-releases/${post.fields.slug}`"
              class="article-post-link text-button"
              >Read More</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { client } from "@/contentful.js";
import { brandKeys } from "@/mixins/brandKeys.js";
import Breadcrumbs from "../components/Breadcrumbs.vue";

export default {
  name: "NewsReleaseListing",
  mixins: [brandKeys],
  components: { Breadcrumbs },
  data() {
    return {
      page_title: null,
      articles: [],
      brand: "",
      limit: 15,
      total: null,
      skip: null,
      search: "",
    };
  },
  methods: {
    getPosts() {
      client
        .getEntries({
          content_type: "launch",
          "fields.brand.sys.id[match]": this.brandId,
          order: "-fields.date",
        })
        .then((res) => {
          console.log(res);
          this.articles = res.items;
        });
    },
  },
  created() {
    this.getPosts();
  },
  computed: {
    filteredList() {
      return this.articles.filter((post) => {
        return post.fields.body
          .toLowerCase()
          .includes(this.search.toLowerCase());
      });
    },
  },
  metaInfo() {
    return {
      title: "News Releases",
      titleTemplate: `%s | ${this.brandName}`,
      meta: [
        {
          vmid: "description",
          name: "description",
          content: `View News Releases from ${this.brandName}`,
        },
      ],
    };
  },
};
</script>
<style scoped>
.search-form {
  margin-top: 22px;
}
.icon-search {
  font-size: 20px;
  position: absolute;
  top: 35px;
  right: 30px;
  color: #ffd100;
}
.article-post-row {
  display: flex;
  flex-direction: row;
}
.content-seperator {
  margin-top: 23px;
}
.article-img-wrapper {
  width: 15%;
  margin-right: 30px;
}
.article-content-wrapper {
  flex: 1;
  margin-bottom: 4em;
}
.article-post-title a h2 {
  text-transform: capitalize;
  color: #000;
  font-size: 2.25rem;
  margin-top: 0;
}
.article-list img {
  aspect-ratio: 1/1;
  object-fit: contain;
  margin-bottom: 2em;
}
.article-meta {
  color: #5b5b5b;
  font-size: 12px;
}
@media (max-width: 768px) {
  .article-post-row {
    margin-bottom: 3em;
    display: flex;
    flex-direction: column;
  }
  .article-img-wrapper {
    width: 100%;
    margin-right: 0;
  }
  .icon-search {
    top: 11px;
  }
}
</style>