<template>
<div class="row">
    <div class="col-sm-12">
<div class="breadcrumbs">
<ul class="breadcrumbs-list">
    <li><a :href="`https://www.hubbell.com/${this.homePath}/en`"><i class="icon-home"></i></a></li>
    <li><router-link :to="this.$route">{{ this.$route.name}}</router-link></li>
    <li v-if="post_title" class="active">{{ post_title }}</li>
</ul>
</div>
</div>
</div>
</template>

<script>
export default {
  props: {
    post_title: String,
    homePath: String
  },
}
</script>
<style scoped>

.breadcrumbs-list {
    list-style: none;
    margin-left: -15px;
    display: inline-block;
    font-size: 11px;
}
.breadcrumbs-list li {
    display: inline-block;
}
.breadcrumbs-list li.active {
    color: #5b5b5b;
}
.breadcrumbs-list li:not(:first-child)::before {
    content: "/";
    margin-left: 5px;
    margin-right: 5px;
}
.breadcrumbs {
  margin: 4px 0 -10px 0;
}

.breadcrumbs-list li a {
  color: #5b5b5b !important;
}
.breadcrumbs-list li.active a {
  color: #5b5b5b !important;
}
</style>