<template>
  <div id="app">
    <template v-if="this.$route.name == 'Video Downloads'">
      <router-view />
    </template>
    <template v-else>
      <Header />
      <div class="view">
        <div
          :class="
            this.$route.name == 'Carousel' ? 'container-fluid' : 'container'
          "
        >
          <router-view />
        </div>
      </div>
      <Footer />
    </template>
  </div>
</template>
<script>
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";

export default {
  components: { Header, Footer },
};
</script>
<style>

#app {
  font-family: "Gotham Book", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000;
}
h1 {
  font-family: "Gotham Bold"!important;
}
h2 {
  font-family: "Gotham Bold" ;
  font-size: 2.75rem;
  letter-spacing: 0;
  line-height: 1.2;
}
h3 {
  font-family: "Gotham Bold" ;
  font-size: 2rem;
  letter-spacing: 0;
  line-height: 1.2;
  font-weight: normal;
}
.view {
  min-height: 100vh;
}
.container {
  max-width: 1130px;
  margin: 20px auto 120px auto;
}

a {
  color: #2d8c9e;
  text-decoration: none;
}
a:hover {
  color: #1c5762;
  text-decoration: none;
}

.btn {
  border-radius: 4px;
  font-size: 12px ;
  padding: 10px 25px ;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-family: "Gotham Bold", Helvetica, Arial, sans-serif;
}
.btn-primary {
  background-color: #ffd100;
  color: #000;
  border-color: #ffd100;
}
.btn:hover {
  background-color: #000 ;
  color: #fff ;
  border-color: #000 ;
}

#at4-share {
  display: none;
}

.show-addthis #at4-share {
  display: block;
  bottom: 30% ;
}
.show-addthis #at4-soc {
  bottom: 30% ;
}
</style>
