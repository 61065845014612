<template>
<div>
  <WDKFooter v-if="brand == 'wdk'"/>
  <HPWFooter v-if="brand == 'hpw'"/>
  <BRYFooter v-if="brand == 'bry'"/>
</div>
</template>
<script>
import { brandKeys } from "@/mixins/brandKeys.js";
import WDKFooter from "@/components/Footers/WDKFooter.vue"
import HPWFooter from "@/components/Footers/HPWFooter.vue"
import BRYFooter from "@/components/Footers/BRYFooter.vue"
export default {
  mixins: [brandKeys],
  components: {
    WDKFooter,
    HPWFooter,
    BRYFooter
  }
};
</script>