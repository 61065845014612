<template>
  <div :class="`carousel-wrapper fade-${results.fields.backgroundColor.replace('#', '')}`" :style="`background-color: ${results.fields.backgroundColor}`">
    <template v-if="loading">
      <div class="loading"></div>
    </template>
    <template v-else>
      <carousel class="carousel-section" :nav="false" :responsive="{
        0: { items: 1, stagePadding: 50, margin: 15 },
        767: { items: 3, stagePadding: 100, margin: 30 },
      }">
              <template v-if="results.fields.cardStyle == 'Hover'">
          <div class="hover__card" v-for="item in results.fields.items" :key="item.fields.title">
            <div class="hover__card__image">
              <picture>
                <img :src="item.fields.image.fields.file.url" class="img-responsive card__image" alt="" />
              </picture>
            </div>
            <div class="hover__card__content" :style="`border-top: 5px solid ${results.fields.cardBorderColor};`">
              <h3 class="hover__card__title">{{ item.fields.title }}</h3>
              <p class="hover__card__description">{{ item.fields.description }}</p>
              <a :href="item.fields.link" class="btn btn-primary hover__card__button" target="_parent" :style="`background-color: ${results.fields.cardBorderColor}; border-color: ${results.fields.cardBorderColor}; color: ${results.fields.cardBorderColor.toUpperCase() != '#FFE100' ? '#FFFFFF' : '#2c2c2c'}`">Explore
                Products</a>
            </div>
          </div>
          <template slot="prev"><span class="prev-arrow"><i class="icon-arrow-left"></i></span></template>
          <template slot="next"><span class="next-arrow"> <i class="icon-arrow-right"></i> </span></template>
        </template>
        <template v-else>
          <a :href="item.fields.link" target="_parent" class="card--full card card__link"
            v-for="item in results.fields.items" :key="item.fields.title">
            <picture>
              <img :src="item.fields.image.fields.file.url" class="img-responsive card__image" alt="" />
            </picture>
            <div class="card__content">
              <div class="card__info">
                <h3 class="card__title">{{ item.fields.title }}</h3>
                <p class="card__description">{{ item.fields.description }}</p>
              </div>
              <div class="card__button">
                <a :href="item.fields.link" class="btn btn-secondary" target="_parent">Explore Products</a>
              </div>
            </div>
          </a>
          <template slot="prev"><span class="prev-arrow"><i class="icon-arrow-left"></i></span></template>
          <template slot="next"><span class="next-arrow"> <i class="icon-arrow-right"></i> </span></template>
        </template>
      </carousel>
    </template>
  </div>
</template>

<script scoped>
// @ is an alias to /src
import carousel from "vue-owl-carousel";
import { client } from "@/contentful";

export default {
  name: "Home",
  components: {
    carousel,
  },
  data() {
    return {
      results: {},
      loading: true,
    };
  },
  mounted() {
    this.getPost();
  },
  methods: {
    getPost() {
      client
        .getEntries({
          content_type: "carousel",
          "fields.slug": this.$route.params.slug,
        })
        .then((res) => {
          console.log(res.items[0]);
          this.results = res.items[0];
          this.loading = false
        });
    },
  },
};
</script>
<style>
.hover__card {
  position: relative;
  transition: all 0.2s ease-in-out;
  border-radius: 4px;
  padding-bottom: 200px;
  z-index: 0;
}

.hover__card .hover__card__image {
  position: relative;
  transition: all 0.3s ease;
  z-index: 0;
  border-radius: 4px 4px 0 0;
}

.hover__card .hover__card__image img {
  display: block;
  max-width: 100%;
  height: auto;
  aspect-ratio: 16 / 9;
  object-fit: contain;
  background-color: #f4f4f4;
}

.hover__card .hover__card__content {
  border-top: 5px solid #ffe100;
  border-radius: 0 0 4px 4px;
  padding: 2em 2em;
  position: absolute;
  height: 200px;
  background-color: #fff;
  z-index: 9999999;
}

.hover__card h3 {
  font-family: "Gotham Medium", Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 24px;
  margin: 0.3em 0;
  line-height: 1.2;
}

.hover__card .hover__card__description {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  font-size: 16px;
  color: #75787b;
  line-height: 1.4;
  font-family: "Gotham Book", Arial, Helvetica, sans-serif;
  font-weight: 400;
}

.hover__card .hover__card__description--short {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
}

.hover__card .hover__card__button {
  margin: 1em 0;
  display: none;
}

.hover__card:hover {
  transition: all 0.2s ease-in-out;
  filter: drop-shadow(0px 4px 4px rgb(0 0 0 / 25%));
  z-index: 50;
}

.hover__card:hover .hover__card__description,
.hover__card:hover .hover__card__description--short {
  display: -webkit-box;
  -webkit-line-clamp: unset;
  -webkit-box-orient: vertical;
  overflow: visible;
  transition: all 0.5s ease-in-out;
}

.hover__card:hover .hover__card__button {
  display: inline-block;
}

.hover__card:hover .hover__card__content {
  height: fit-content;
}

@media screen and (max-width: 767px) {

  .hover__card {
    flex-direction: column;
    padding-bottom: 0;
  }

  .hover__card [class^="card__description"] {
    -webkit-line-clamp: unset;
  }

  .hover__card:hover {
    filter: none;
  }

  .hover__card .hover__card__button {
    display: inline-block;
  }

  .hover__card .hover__card__content {
    position: relative;
    height: unset;
  }
}

.btn {
  border-radius: 4px;
}

.carousel-wrapper {
  height: 100vh;
  position: relative;
}

.carousel-section {
  position: relative;
}

.owl-stage {
  /* padding-left: 0 !important; */
  display: flex;
}

.owl-stage-outer {
  padding-bottom: 15px;
  overflow: visible!important
}

.prev-arrow,
.next-arrow {
  width: 50px;
  height: 50px;
  padding: 10px;
  font-size: 24px;
  background: #fff !important;
  box-shadow: -1px 4px 8px rgb(0 0 0 / 20%);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #d8d8d8;
  position: absolute;
  top: 40%;
  transition: 0.3s all;
  z-index: 9999;
  cursor: pointer;
}

.prev-arrow:hover,
.next-arrow:hover {
  box-shadow: -1px 4px 10px rgb(0 0 0 / 30%);
  cursor: pointer;
}

.prev-arrow {
  left: 80px;
}

.next-arrow {
  right: 80px;
}

.owl-prev:hover,
.owl-next:hover {
  box-shadow: -1px 4px 8px rgb(0 0 0 / 40%);
  color: #c9c9c9;
  transition: 0.3s all;
}

.owl-prev:before {
  font-family: hubicons;
  content: "3";
}

.owl-next:before {
  font-family: hubicons;
  content: "4";
}

.owl-item {
  position: relative;
  transition: all 0.3s ease;
  background-color: #fff;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  /*overflow: hidden;*/
  display: flex;
  flex-direction: column;
}

.card__link {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.card__content {
  border-top: 6px solid #ffd100;
  /*this should be brand primary*/
  padding: 2em 2em;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.card__content h3 {
  margin-top: 0;
}

.card__title {
  text-decoration: none !important;
  color: #2c2c2c !important;
}

.card__description {
  text-decoration: none !important;
  color: #2c2c2c !important;
  margin-bottom: 1.5em;
}

.card__button {
  margin-top: auto;
}

.owl-item:hover {
  box-shadow: 0px 6px 10px 0px rgba(20, 20, 20, 0.2);
}

.fade-F4F4F4 .owl-stage-outer::after {
  content: "";
  width: 100px;
  height: 100%;
  background: linear-gradient(to right, #f4f4f410, #f4f4f4);
  position: absolute;
  top: 0;
  right: 0;
}

.fade-F4F4F4 .owl-stage-outer::before {
  content: "";
  width: 100px;
  height: 100%;
  background: linear-gradient(to left, #f4f4f100, #f4f4f4);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
}
.fade-FFFFFF .owl-stage-outer::after {
  content: "";
  width: 100px;
  height: 100%;
  background: linear-gradient(to right, #ffffff10, #ffffff);
  position: absolute;
  top: 0;
  right: 0;
}

.fade-FFFFFF .owl-stage-outer::before {
  content: "";
  width: 100px;
  height: 100%;
  background: linear-gradient(to left, #ffffff10, #ffffff);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
}
.loading {
  position: absolute;
  left: 50%;
  top: 10%;
  transform: translate(-50%, 0);

}

.loading:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #d8d8d8;
  border-color: #d8d8d8 #d8d8d800 #d8d8d8 #d8d8d800;
  -webkit-animation: loading 1.2s linear infinite;
  animation: loading 1.2s linear infinite;
}

@-webkit-keyframes loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 767px) {
  .owl-stage-outer::after {
    width: 50px;
  }

  .owl-stage-outer::before {
    width: 50px;
  }

  .card__content {
    border-top: 6px solid #ffd100;
    /*this should be brand primary*/
    padding: 1.5em;
  }

  .prev-arrow {
    left: 5px;
  }

  .next-arrow {
    right: 5px;
  }
}
</style>