<template>
    <div></div>
</template>
<script>
import redirects from "@/assets/json/redirects.json"
import { brandKeys } from "@/mixins/brandKeys.js";

export default {
    mixins: [brandKeys],
    created() {
        this.redirectVideo()
    },
    methods: {
        redirectVideo() {
            console.log(this.$route.path)
            var res = redirects.find(el => el.path == this.$route.path)
            console.log(res)

            if (res) {
                window.location.href = res.redirect
            }
            else {
                window.location.href = `https://www.hubbell.com/${this.homePath}/en/videos`
            }
        }
    }
}
</script>