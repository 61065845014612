<template>
    <countdown :time="time">
        <template slot-scope="props">
            <div class="countdown__wrapper">
                <div class="countdown__item">
                    <div class="countdown__number">{{ props.days }}</div>
                    <div class="countdown__name">Days</div>
                </div>
                <div class="countdown__item">
                    <div class="countdown__number">{{ props.hours }}</div>
                    <div class="countdown__name">Hours</div>
                </div>
                <div class="countdown__item">
                    <div class="countdown__number">{{ props.minutes }}</div>
                    <div class="countdown__name">Minutes</div>
                </div>
                <div class="countdown__item">
                    <div class="countdown__number">{{ props.seconds }}</div>
                    <div class="countdown__name">Seconds</div>
                </div>
            </div>
        </template>
    </countdown>
    <!-- <span>Time Remaining：1 days, 23 hours, 59 minutes, 59 seconds.</span> -->
</template>
<script>
export default {
    data() {
        const now = new Date();
        const eventDate = new Date('April 15, 2024');

        return {
            time: eventDate - now,
        };
    },
    methods: {
        transform(props) {
            Object.entries(props).forEach(([key, value]) => {
                // Adds leading zero
                const digits = value < 10 ? `0${value}` : value;

                // uses singular form when the value is less than 2
                const word = value < 2 ? key.replace(/s$/, '') : key;

                props[key] = `${digits} ${word}`;
            });

            return props;
        },
    },
};
</script>
<style>
body {
    background-color: transparent
}
.countdown__wrapper {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    gap: 32px;
}

.countdown__item {
    text-align: center;
    width: 160px;
}

.countdown__number {
    font-family: "Gotham Book", Arial, Helvetica, sans-serif;
    font-size: 88px;
    color: #FFE100;
    letter-spacing: 0;
    text-align: center;
    line-height: 56px;
    font-weight: 400;
    margin-bottom: 24px;
}

.countdown__name {
    font-family: "Gotham Book", Arial, Helvetica, sans-serif;
    font-size: 32px;
    color: #FFFFFF;
    letter-spacing: 0;
    text-align: center;
    line-height: 32px;
    font-weight: 400;
}
@media screen and (max-width: 767px) {
    .countdown__number {
    font-size: 32px;
    line-height: 1;
    margin-bottom: 16px;
}

.countdown__name {
    font-family: "Gotham Book", Arial, Helvetica, sans-serif;
    font-size: 16px;
    line-height: 1;
}
}
</style>