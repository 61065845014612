<template>
  <div class="fmiprogram">
    <div class="fmi-header-wrapper">
      <div class="fmi-header container">
        <div class="fmi-header-logo" style="align-items: flex-start">
          <a
            href="https://www.fastenal.com/product?query=hubbell"
            target="blank"
            ><img
              src="https://hubbellcdn.com/ohwassets/HCI/WiringDevice/fmiprogram/Fastenal-Logo_blk.png"
          /></a>
        </div>
        <div class="fmi-header-logo" style="align-items: center">
          <a href="https://www.hubbell.com/" target="blank"
            ><img
              src="https://images.ctfassets.net/ju50mspzo77h/27obgPFISj7ymnB4GsHKc1/35bd0bb3b34805e0c1920056b0d44780/Hubbell_Logo_No_R_300_DPI.png?fm=webp&q=90&w=200&h="
          /></a>
        </div>
        <div class="fmi-header-logo" style="align-items: flex-end">
          <a href="https://www.linedriveu.com/" target="blank"
            ><img
              src="https://hubbellcdn.com/ohwassets/HCI/WiringDevice/fmiprogram/linedrive-logo.jpg"
          /></a>
        </div>
      </div>
    </div>
    <div class="fmi-subheader-wrapper">
      <div class="fmi-subheader container">
        <div class="fmi-subheader-logo" v-for="item in brands" :key="item.logo">
          <a :href="item.link" target="_blank"><img :src="item.logo" /></a>
        </div>
      </div>
    </div>
    <div class="fmi-hero">
      <img
        src="https://www.hubbell.com/medias/boxes-homepage-hero-2-1-.webp?context=bWFzdGVyfGltYWdlc3w3NzQ0MHxhcHBsaWNhdGlvbi9vY3RldC1zdHJlYW18aW1hZ2VzL2g2Ny9oNGQvaDAwLzkxNDg0ODU5MjY5NDIuYmlufDdkMTE2ZDkxYjg1YTY0MzgzMTYzZjQxYzdjZWI4YWRlZGU5YjY1M2M0MDc3YTI1NGQ2NTgyMzBkMTUwZTVjMzY"
      />
    </div>
    <div class="container">
      <div class="asset-container">
        <div class="featured-asset-list-wrapper">
          <div class="asset" v-for="item in featuredAssetList" :key="item.name">
            <h3>{{ item.name }}</h3>
            <ul>
              <li v-for="item in item.items" :key="item.name">
                <a :href="item.link" target="_blank"><i :class="`icon-${item.type}`"></i>{{ item.name }}</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="asset-list-wrapper">
          <div class="asset" v-for="item in assetList" :key="item.name">
            <h3>{{ item.name }}</h3>
            <div class="asset-list-container">
              <div v-if="item.image" class="asset-image">
                <img :src="item.image" />
              </div>
              <div class="asset-list">
                <ul>
                  <li v-for="item in item.items" :key="item.name">
                    <a :href="item.link" target="_blank"><i :class="`icon-${item.type}`"></i>{{ item.name }}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="fmi-footer">
      <div class="fmi-header-logo" style="align-items: center">
        <a href="https://www.hubbell.com/" target="blank"
          ><img
            src="https://images.ctfassets.net/ju50mspzo77h/27obgPFISj7ymnB4GsHKc1/35bd0bb3b34805e0c1920056b0d44780/Hubbell_Logo_No_R_300_DPI.png?fm=webp&q=90&w=200&h="
        /></a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      brands: [
        {
          logo: "https://www.hubbell.com/medias/logo-wiringDevice-Kellems.png?context=bWFzdGVyfGltYWdlc3w1MjU2M3xpbWFnZS9wbmd8aW1hZ2VzL2g2OS9oYjcvODgwMDM1MTk3NzUwMi5wbmd8ZGVhYmQ0YjliNjg2OGQ3OTUxMTQ1NjM0NmY4ODg4Y2MyNmY2NzYyMTAzY2JjMDA5OTQwZDljZDY0OTM3YzM3OQ",
          link: "https://www.hubbell.com/wiringdevice-kellems/en",
        },
        {
          logo: "https://www.hubbell.com/medias/logo-bryant.png?context=bWFzdGVyfGltYWdlc3w1NTIyfGltYWdlL3BuZ3xpbWFnZXMvaDY2L2hjNi84ODAwOTM3MzQ1MDU0LnBuZ3wzYzNiYjA5YzU4NTg3YjkzN2U3MGM3ZjBjM2I3ZTI0MjUxYjZjN2M2MzJkYjg5OGM1NmI0YzQ3YmJiMzAxMDUx",
          link: "https://www.hubbell.com/bryant/en/",
        },
        {
          logo: "https://www.hubbell.com/medias/RACObanner.png?context=bWFzdGVyfGltYWdlc3wyOTkyNTN8aW1hZ2UvcG5nfGltYWdlcy9oOTQvaDg5Lzg4Mjc5NjM5OTgyMzgucG5nfDk2N2YxODg0ZjFiY2I3ZDNkMTUzZjE5MjQ5OWZkYzk5MTRjMTE4ODAwZWUyYWUwZmU1ZWExZDY3MjhkN2U0NDU",
          link: "https://www.hubbell.com/raco/en/",
        },
        {
          logo: "https://www.hubbell.com/medias/Taymacbanner.png?context=bWFzdGVyfGltYWdlc3wyOTc0NTF8aW1hZ2UvcG5nfGltYWdlcy9oZTUvaDNlLzg4Mjc5NjU3MzQ5NDIucG5nfDE2ZDFmZjhiODc1MzMxZDU2NDFkOThmNzVmNTkzZTc0MDQ4OThiYzgwODA1Y2VmZDI4ZjY0ZDBjZGIzOWIyNzU",
          link: "https://www.hubbell.com/taymac/en/",
        },
        {
          logo: "https://www.hubbell.com/medias/BELLbanner.png?context=bWFzdGVyfGltYWdlc3wyOTc0NTF8aW1hZ2UvcG5nfGltYWdlcy9oMDIvaGIxLzg4Mjc5NjU1MzgzMzQucG5nfDI5ODZiNzIwMjUxZjQ2ZTBmMDEzNTE1MzNlZDI0OTEyYmY3NGU1ZjhmZmExZDc0ODIyODU5MThjODAzMWY0MTY",
          link: "https://www.hubbell.com/bell/en/",
        },
        {
          logo: "https://www.hubbell.com/medias/WiegmannMedBlueLogo.png?context=bWFzdGVyfGltYWdlc3wzOTgyfGltYWdlL3BuZ3xpbWFnZXMvaDM4L2g1MS9oMDAvOTA2NTI5MTMxNzI3OC5wbmd8ZjUzY2U5NjBiZDQ3NzMzN2JkYzhjY2NmMDdjOTk0ZjAyODFjYjVkNDcxNThiYzU1YTJhOWQ5ZGM0MGEyNDNjOA",
          link: "https://www.hubbell.com/wiegmann/en/",
        },
        {
          logo: "https://www.hubbell.com/medias/logo-burndy.png?context=bWFzdGVyfGltYWdlc3w0NTEzfGltYWdlL3BuZ3xpbWFnZXMvaGQ0L2hiMC84ODAwMzQ1NjIwNTEwLnBuZ3wyOThmMmY5NzU3NmRiZTUwNzQ5NzZlNmJiZDJlODBlYzFkMWUyOGQ1OGYxODA3MDBlNzkwMGEyNGQzNDc3OGI3",
          link: "https://www.hubbell.com/burndy/en/",
        },
        {
          logo: "https://www.hubbell.com/medias/logo-killark.png?context=bWFzdGVyfGltYWdlc3wyNjAyfGltYWdlL3BuZ3xpbWFnZXMvaDI2L2hhOC84ODAwNjMzMzIzNTUwLnBuZ3xjYzMwZGQ1OTQxODBjZDUzOWM3OGI0YzU0NTM1YTk2OTZmMTc1NjIzZjMxZmVkYWYyMzY3ODVhOWVjZmZmMTJm",
          link: "https://www.hubbell.com/killark/en/",
        },
        {
          logo: "https://www.hubbell.com/medias/logo-gleason.png?context=bWFzdGVyfGltYWdlc3w4MzY5fGltYWdlL3BuZ3xpbWFnZXMvaDIzL2gxMi84ODAyNzk3MDkyODk0LnBuZ3w1MjhmNGRiMjlkMDQwNTJiMjUxZmE5NTBlOTgxYWUzYjczZGVhMzMwMGUwNjk4NWYxZGFiMWNhN2IzOWZjNzg3",
          link: "https://www.hubbell.com/gleasonreel/en/",
        },
      ],
      assetList: [
        {
          name: "BIN Stock / Vending Products",
          image: "https://hubbellcdn.com/ohwassets/HCI/WiringDevice/fmiprogram/WLBDIST003-cover.jpg",
          items: [
            {
              name: "Bin Stocking Guide",
              link: "https://hubbellcdn.com/ohwassets/HCI/WiringDevice/literature/WLBDIST003.pdf",
              type: "file-pdf",
            },
            {
              name: "Plugs & Connectors",
              link: "https://hubbellcdn.com/ohwassets/HCI/WiringDevice/literature/Fast_Insulgrip_Brochure.pdf",
              type: "file-pdf",
            },
            {
              name: "Product NEMA Chart",
              link: "https://hubbellcdn.com/ohwassets/HCI/WiringDevice/media/NEMA_Chart.pdf",
              type: "file-pdf",
            },
            {
              name: "Bin Stock Guide - Government/Education",
              link: "https://hubbellcdn.com/ohwassets/HCI/WiringDevice/literature/WLBIN001FA_BIN_GUIDE.pdf",
              type: "file-pdf",
            },
            {
              name: "Bin Stock Guide - Healthcare",
              link: "https://hubbellcdn.com/ohwassets/HCI/WiringDevice/literature/WLBIN002FA.pdf",
              type: "file-pdf",
            },
          ],
        },
        {
          name: "FMI / VMI / Vending",
          image: "https://hubbellcdn.com/ohwassets/HCI/WiringDevice/fmiprogram/WLBDIST003-cover.jpg",
          items: [
            {
              name: "Certified Vending Items",
              link: "https://hubbellcdn.com/ohwassets/HCI/WiringDevice/literature/Certified_Vending-Items_Hubbell.pdf",
              type: "file-pdf",
            },
            {
              name: "Key Bin Stock Items",
              link: "https://hubbellcdn.com/ohwassets/HCI/WiringDevice/literature/Bin_Stocking-Items_Hubbell.pdf",
              type: "file-pdf",
            },
            {
              name: "Bin Stock Flyer",
              link: "https://hubbellcdn.com/ohwassets/HCI/WiringDevice/literature/Fastenal_Bin_Stocking_Flyer.pdf",
              type: "file-pdf",
            },
          ],
        },
        {
          name: "Bin Stock Presentations",
          image: "",
          items: [
            {
              name: "Hubbell Bin Stock Opportunities",
              link: "https://hubbellcdn.com/ohwassets/HCI/WiringDevice/literature/Hubbell_Incorporated_Bin_Stocking_Opportunties.pdf",
              type: "file-pdf",
            },
            {
              name: "Introduction to Hubbell",
              link: "https://hubbellcdn.com/ohwassets/HCI/WiringDevice/literature/Hubbell_Incorporated_Introduction.pdf",
              type: "file-pdf",
            },
            {
              name: "Quotation Process",
              link: "https://hubbellcdn.com/ohwassets/HCI/WiringDevice/literature/Hubbell_Quotation_Requests.pdf",
              type: "file-pdf",
            },
            {
              name: "Competitive Opportunities",
              link: "https://hubbellcdn.com/ohwassets/HCI/WiringDevice/literature/Hubbell_Competitive_Opportunties.pdf",
              type: "file-pdf",
            },
          ],
        },
        {
          name: "Plan-O-Gram",
          items: [
            {
              name: "Hubbell Wiring Device-Kellems",
              link: "https://hubbellcdn.com/ohwassets/HCI/WiringDevice/literature/HUBBELL-135794-WDK-72_Hole.pdf",
              type: "file-pdf",
            },
            {
              name: "RACO / Killark",
              link: "https://hubbellcdn.com/ohwassets/HCI/WiringDevice/literature/HUBBELL-131972-HEP-72_Hole-FINAL_POG.pdf",
              type: "file-pdf",
            },
            {
              name: "Bryant Electric",
              link: "https://hubbellcdn.com/ohwassets/HCI/WiringDevice/literature/BRYANT-132611-40_Hole-FINAL_POG.pdf",
              type: "file-pdf",
            },
          ],
        },
        {
          name: "Goverment / Education",
          items: [
            {
              name: "Government / Education Sell Sheet",
              link: "https://hubbellcdn.com/ohwassets/HCI/WiringDevice/fmiprogram/WLBVA002FA.pdf",
              type: "file-pdf",
            },
            {
              name: "Education Brochure",
              link: "https://hubbellcdn.com/ohwassets/HCI/WiringDevice/fmiprogram/WLBVM004_Education_rev_1-12.pdf",
              type: "file-pdf",
            },
            {
              name: "H-MOSS PIR Occupancy Sensors",
              link: "https://hubbellcdn.com/ohwassets/HCI/WiringDevice/fmiprogram/WLDHM028.pdf",
              type: "file-pdf",
            },
            {
              name: "Hubbell Insulgrip Devices",
              link: "https://www.youtube.com/watch?v=KdGfwPLcUhI",
              type: "film",
            },
            {
              name: "Hubbell Watertight Devices",
              link: "https://www.youtube.com/watch?v=ZP-jfAAABN0",
              type: "film",
            },
            {
              name: "Hubbell Safety Product Guide",
              link: "https://www.youtube.com/watch?v=KdGfwPLcUhI",
              type: "film",
            },
          ],
        },
        {
          name: "Food Processing",
          items: [
            {
              name: "Food Processing Brochure",
              link: "https://hubbellcdn.com/ohwassets/HCI/WiringDevice/literature/WLBVM010FAS.pdf",
              type: "file-pdf",
            },
            {
              name: "Watertight Wiring Devices",
              link: "https://hubbellcdn.com/ohwassets/HCI/WiringDevice/literature/WLBWT002FAS.pdf",
              type: "file-pdf",
            },
            {
              name: "Stainless Steel Disconnects",
              link: "https://hubbellcdn.com/ohwassets/HCI/WiringDevice/literature/WLDS006.pdf",
              type: "file-pdf",
            },
            {
              name: "Manual Motor Controllers & Disconnects",
              link: "https://hubbellcdn.com/ohwassets/HCI/WiringDevice/literature/WLBSB001.pdf",
              type: "file-pdf",
            },
            {
              name: "Switch Rated IEC Pin & Sleeve - 30A",
              link: "https://hubbellcdn.com/ohwassets/HCI/WiringDevice/literature/WLBP001.pdf",
              type: "file-pdf",
            },
            {
              name: "Switch Rated IEC Pin & Sleeve - 60A",
              link: "https://hubbellcdn.com/ohwassets/HCI/WiringDevice/literature/WLDP006.pdf",
              type: "file-pdf",
            },
          ],
        },
      ],
      featuredAssetList: [
        {
          name: "Key Literature",
          items: [
            {
              name: "Safety Guide",
              link: "https://hubbellcdn.com/ohwassets/HCI/WiringDevice/literature/WLBVM002FA2011.pdf",
              type: "file-pdf",
            },
            {
              name: "USB Receptacles",
              link: "https://hubbellcdn.com/literature/WIRING_WLBTS008.pdf",
              type: "file-pdf",
            },
            {
              name: "Occupancy Sensors",
              link: "https://hubbellcdn.com/literature/Wiring_WLBHM008.pdf",
              type: "file-pdf",
            },
            {
              name: "Bryant MRO Devices",
              link: "https://hubbellcdn.com/ohwassets/HCI/Bryant/literature/BLDVM001_MRO.pdf",
              type: "file-pdf",
            },
            {
              name: "Market Ops Guide",
              link: "https://hubbellcdn.com/ohwassets/HCI/WiringDevice/literature/WLBFAST002.pdf",
              type: "file-pdf",
            },
            {
              name: "inSIGHT™ Data Monitoring",
              link: "https://hubbellcdn.com/ohwassets/HCI/WiringDevice/literature/WLBDIST005.pdf",
              type: "file-pdf",
            },
          ],
        },
        {
          name: "Sales Logistics",
          items: [
            {
              name: "Hubbell-Fastenal Line Card",
              link: "https://hubbellcdn.com/ohwassets/HCI/WiringDevice/literature/zWLBFAST001B.pdf",
              type: "file-pdf",
            },
            {
              name: "LineDrive Outside Sales Representatives",
              link: "https://www.linedriveu.com/sales-team",
              type: "link",
            },
            {
              name: "Bin Stock Project Form",
              link: "https://hubbellcdn.com/ohwassets/HCI/WiringDevice/literature/HBL_Bin_Stock_Project_Form.pdf",
              type: "file-pdf",
            },
          ],
        },
        {
          name: "Interactive eTours",
          items: [
            {
              name: "Safety eTour",
              link: "https://hubbellcdn.com/ohwassets/HCI/WiringDevice/eTours/safety/index.html",
              type: "link",
            },
            {
              name: "Education eTour",
              link: "https://hciapps.hubbell.com/hcipunchout/eTour/eTourViewerPC.aspx?eTourID=8&CompanyCode=3A",
              type: "link",
            },
            {
              name: "MRO Facilities eTour",
              link: "https://hubbellcdn.com/ohwassets/HCI/WiringDevice/eTours/industrial/index.html",
              type: "link",
            },
            {
              name: "Healthcare eTour",
              link: "https://hubbellcdn.com/ohwassets/HCI/WiringDevice/eTours/healthcare/index.html",
              type: "link",
            },
          ],
        },
      ],
    };
  },
  metaInfo() {
    return {
      title: "Hubbell FMI Program",
      titleTemplate: `%s | Hubbell`,
      meta: [
        {
          vmid: "description",
          name: "description",
          content: `Hubbell FMI Program`,
        },
      ],
    };
  },
};
</script>
<style scoped>
.container {
  margin: 0 auto 0 auto;
}
.fmi-header-wrapper {
  background-color: #fff;
  padding: 20px;
}
.fmi-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 100px;
  width: 100%;
}

.fmi-header-logo {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.fmi-header img {
  max-width: 300px;
  max-height: 50px;
}
.fmi-subheader-wrapper {
  background-color: #f4f4f4;
  padding: 20px;
}
.fmi-subheader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 80px;
  width: 100%;
}
.fmi-subheader-logo {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.fmi-subheader img {
  max-width: 120px;
  width: 100%;
  max-height: 40px;
  padding: 0 10px;
}
.fmi-hero {
  width: 100%;
  max-height: 400px;
}
.fmi-hero img {
  width: 100%;
  margin: 0 auto;
  max-height: 400px;
  object-fit: cover;
}
.asset-container {
  display: flex;
  margin: 5em 0;
}
.featured-asset-list-wrapper {
  margin-top: 1em;
  background-color: #f4f4f4;
  padding: 10px 25px;
  width: 25%;
}
.featured-asset-list-wrapper .asset {
  margin-bottom: 3em;
}
.asset-list-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.asset-list-wrapper .asset {
  margin: 15px;
  border: 1px solid #d8d8d8;
  padding: 5px 25px 25px 25px;
}
.asset-list-container {
  display: flex;
}
.asset-image img {
    max-width: 100px;
    margin-right: 15px;
}
.asset li {
    list-style: none;
    margin-bottom: .6em;
    margin-left: -12px;
}
.asset li i{
    margin-right: 5px;
}
.fmi-footer {
  background-color: #2c2a29;
  padding: 50px;
}
.fmi-footer img {
  max-width: 100px;
}
@media screen and (max-width: 767px) {
  .fmi-header img {
    max-width: 100px;
  }
  .fmi-subheader img {
    max-width: 120px;
    width: 100%;
    max-height: 40px;
    padding: 0 5px;
  }
  .featured-asset-list-wrapper {
    margin: 14px;
    width: unset;
  }
  .asset-container {
    display: flex;
    flex-direction: column-reverse;
  }
  .asset-list-wrapper {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>