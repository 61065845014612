export const brandKeys = {
    data() {
        return {
            brand: '',
            brandId: '',
            playlistId: '',
            homePath: '',
            brandName: ''
        }
    },
    created: function () {
        this.getBrandKeysfunction();
    },
    watch: {
        "$route.params": function () {
            this.getBrandKeysfunction();
        },
    },
    methods: {
        getBrandKeysfunction: function () {
            var brand = this.$route.params.brand;
            switch (brand) {
                case 'wdk':
                    this.brandId = '2QoXO9zmT3s5xnnDVy3vDg';
                    this.playlistId = 'PLLs0Y646AbNpPhwX2YP75KmWTl82AWK20';
                    this.brand = brand;
                    this.homePath = "wiringdevice-kellems",
                    this.brandName = "Wiring Device-Kellems"
                    break;
                case 'hpw':
                    this.brandId = '2W3xQWH3BgjyL8naqeP6OY'
                    this.playlistId = 'PLpStr-EnLZMUZHwXTYw9zYKp9gAH3l_lQ';
                    this.brand = brand;
                    this.homePath = "hubbellpremisewiring",
                    this.brandName = "Hubbell Premise Wiring"
                    break;
                case 'bry':
                    this.brandId = '2NcRmlNpwKkkFxTZ4GHZmF'
                    this.playlistId = '';
                    this.brand = brand;
                    this.homePath = "bryant",
                    this.brandName = "Bryant"
                    break;
                case 'raco':
                    this.brandId = '2NcRmlNpwKkkFxTZ4GHZmF'
                    this.playlistId = '';
                    this.brand = brand;
                    this.homePath = "raco",
                    this.brandName = "Raco"
                    break;
                case 'taymac':
                    this.brandId = '2NcRmlNpwKkkFxTZ4GHZmF'
                    this.playlistId = '';
                    this.brand = brand;
                    this.homePath = "taymac",
                    this.brandName = "TayMac"
                    break;
                case 'bell':
                    this.brandId = '2NcRmlNpwKkkFxTZ4GHZmF'
                    this.playlistId = '';
                    this.brand = brand;
                    this.homePath = "bell",
                    this.brandName = "Bell"
                    break;
                case 'wiegmann':
                    this.brandId = '2NcRmlNpwKkkFxTZ4GHZmF'
                    this.playlistId = '';
                    this.brand = brand;
                    this.homePath = "wiegmann",
                    this.brandName = "Wiegmann"
                    break;
            }
        }
    }
}