import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import NewsReleaseListing from '../views/NewsReleaseListing.vue'
import NewsReleasePost from '../views/NewsReleasePost.vue'
import FMIProgram from '../views/FMIProgram.vue'
import Carousel from '../views/Carousel.vue'
import Preview from '../views/Preview.vue'
import VideoRedirect from '../views/VideoRedirect.vue'
import VideoDownloads from '../views/VideoDownloads.vue'
import CountDown from '../views/CountDown.vue'



Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/iframes/carousels/:slug',
    name: 'Carousel',
    component: Carousel,
  },
  {
    path: '/iframes/countdown',
    name: 'CountDown',
    component: CountDown,
  },
  {
    path: '/news-releases/:slug/preview',
    alias: '/:brand/news-releases/:slug/preview',
    name: 'Preview',
    component: Preview,
  },
  {
    path: '/:brand/news-releases',
    name: 'News Releases',
    component: NewsReleaseListing,
  },
  {
    path: '/:brand/video-downloads/',
    name: 'Video Downloads',
    component: VideoDownloads,
  },
  {
    path: '/:brand/video-library/',
    name: 'Video Redirect',
    component: VideoRedirect,
  },
  {
    path: '/:brand/video-library/:id',
    name: 'Video Post Redirect',
    component: VideoRedirect,
  },
  {
    path: '/:brand/:slug',
    alias: '/:brand/news-releases/:slug',
    name: 'News Releases',
    component: NewsReleasePost,
  },
  {
    path: '/fmiprogram',
    name: 'FMI Program',
    component: FMIProgram,
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
          return savedPosition
      }
      if (to.hash) {
          return { selector: to.hash }
      } else {
          return { x: 0, y: 0 }
      }
  }
})

export default router
